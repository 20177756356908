import {Form, FormControlProps, FormGroupProps} from 'react-bootstrap';
import React, {FC, useId} from 'react';
import cn from 'classnames';

interface ExtraFormGroupProps extends Omit<FormGroupProps, 'controlId'> {
  label: string;
  required?: boolean;
  id?: string;
}

const ExtraFormGroup: FC<ExtraFormGroupProps> = (props) => {
  const { className, label, required, id: _id, children, ...rest } = props;
  const internalId = useId();
  const id = _id ?? internalId;
  const classes = cn('form-group', className);

  return (
    <Form.Group className={classes} controlId={id} {...rest}>
      <Form.Label>
        {label}
        {required && <span className="form-required">*</span>}
      </Form.Label>
      {children}
    </Form.Group>
  );
};

interface ExtraFormControlProps extends FormControlProps {
  error?: string;
  touched?: boolean;
}

const ExtraFormControl: FC<ExtraFormControlProps> = (props) => {
  const { error, touched, ...rest } = props;
  return (
    <React.Fragment>
      <Form.Control {...rest} isInvalid={touched && error !== undefined} />
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </React.Fragment>
  );
};

const ExtraFormNamespace = Object.assign(
  {},
  {
    Group: ExtraFormGroup,
    Control: ExtraFormControl,
  },
);

//TODO: Add ExtraForm.Select

export { ExtraFormNamespace as ExtraForm };
