import {Table} from "react-bootstrap";
import {useTaskActions, useTasks} from "./useTasks";
import Lottie from "lottie-react";
import NoItems from './NoItems.json';
import Loading from './Loading.json';
import {ExtraButton} from "../common/pure/ExtraButton";
import {IconEdit, IconPlus, IconTrash} from "@tabler/icons-react";
import {useModal} from "../common/modal/Modal";
import {ModalTask} from "./ModalTask";
import {TaskDto} from "shared";
import {Suspense} from "react";
import {TimeFormatter, useTime} from "../common/time/useTime";

const colSpan = 3;

const TaskTableData = () => {
    const {tasks} = useTasks();
    const {del} = useTaskActions();
    const {open, confirm} = useModal();
    const {format} = useTime();

    const handleModal = (task?: TaskDto) => open(ModalTask, {task})
    const handleDelete = (id: number) => confirm({
        title: 'Delete task',
        children: 'Are you sure you want to delete this task?',
        variant: 'danger',
        size: 'sm',
        confirmText: 'Delete',
        onConfirm: () => del.mutateAsync(id),
    })

    return <>
        {tasks.length === 0 && <tr>
            <td colSpan={colSpan}>
                <div className='table-empty'>
                    <div className='table-empty-icon' style={{aspectRatio: '2/1'}}>
                        <Lottie animationData={NoItems}/>
                    </div>
                    No tasks to see.. Well done!
                    <ExtraButton icon={IconPlus} onClick={() => handleModal()}>
                        Add new task
                    </ExtraButton>
                </div>
            </td>
        </tr>}
        {tasks.map(x => <tr key={x.id}>
            <td style={{verticalAlign: 'middle'}}>{x.task}</td>
            <td style={{verticalAlign: 'middle'}}>{format(TimeFormatter.DateTime, x.createdAt)}</td>
            <td className='text-right' align='right' style={{verticalAlign: 'middle'}}>
                <div className='d-inline-flex gap-1'>
                    <ExtraButton size='sm'
                                 icon={IconEdit}
                                 onClick={() => handleModal(x)}>
                        Edit
                    </ExtraButton>
                    <ExtraButton size='sm'
                                 variant='danger'
                                 icon={IconTrash}
                                 onClick={() => handleDelete(x.id)}>
                        Delete
                    </ExtraButton>
                </div>
            </td>
        </tr>)}
    </>
}

const TaskTableLoading = () => {
    return <tr>
        <td colSpan={colSpan}>
            <div className='table-empty'>
                <div className='table-empty-icon'>
                    <Lottie animationData={Loading}/>
                </div>
                Loading...
            </div>
        </td>
    </tr>
}


export const TaskTable = () => {
    return <Table className='mb-0' responsive hover>
        <thead>
        <tr>
            <th>Task</th>
            <th>Created at</th>
            <th/>
        </tr>
        </thead>
        <tbody>
        <Suspense fallback={<TaskTableLoading/>}>
            <TaskTableData/>
        </Suspense>
        </tbody>
    </Table>
}