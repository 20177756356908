import {createContext, FC, PropsWithChildren, ReactNode, useContext, useEffect, useState} from "react";
import {useEventCallback} from "usehooks-ts";

type AlertEmit = (alert: Alert) => unknown;
const noScopeFn = () => {
    throw new Error("No AlertScope found. Define AlertScope in a higher component than the current one");
};

enum AlertType {
    INFO = 'info',
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

interface Alert {
    type: AlertType;
    content: ReactNode | string;
}


type AlertSubscription = (alert: Alert) => unknown;
type AlertSubscribe = (subscription: AlertSubscription) => AlertSubscription;
type AlertUnsubscribe = () => void;

const AlertContext = createContext({
    subscription: undefined as AlertSubscription | undefined,
    subscribe: noScopeFn as AlertSubscribe,
    unsubscribe: noScopeFn as AlertUnsubscribe,
    emit: noScopeFn as AlertEmit,
});

interface AlertScopeProps extends PropsWithChildren {
    filter?: AlertType[];
}

const AlertScope: FC<AlertScopeProps> = props => {
    const {
        children,
        filter,
    } = props;
    const reEmit = useAlertEmit();
    const [subscription, setSubscription] = useState<AlertSubscription>();


    const subscribe = useEventCallback(((subscription) => {
        setSubscription((x: AlertSubscription | undefined) => {
            if (x !== undefined) {
                throw new Error('Can only subscribe to a scope once');
            }

            return subscription;
        });

        return subscription;
    }) as AlertSubscribe);

    const unsubscribe = useEventCallback((() => {
        setSubscription(undefined)
    }) as AlertUnsubscribe);

    const emit = useEventCallback((alert => {
        if (subscription === undefined) {
            throw new Error('Could not find alert handler in this scope');
        }

        if (!filter) {
            subscription(alert);
            return;
        }

        if (filter.includes(alert.type)) {
            subscription(alert);
        } else {
            reEmit(alert);
        }
    }) as AlertEmit);


    return <AlertContext.Provider value={{subscription, subscribe, unsubscribe, emit}}>
        {children}
    </AlertContext.Provider>
}

const useAlertEmit = () => useContext(AlertContext).emit
const useAlertSubscribe = (onReceive: AlertSubscription) => {
    const {subscribe, unsubscribe} = useContext(AlertContext);
    const _onReceive = useEventCallback(onReceive);

    useEffect(() => {
        subscribe(_onReceive);

        return () => {
            unsubscribe();
        }
    }, [subscribe, unsubscribe, _onReceive])
}

export {
    AlertType,
    AlertScope,
    useAlertEmit,
    useAlertSubscribe,
};

export type {Alert};