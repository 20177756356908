import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {IconHome, IconListCheck, IconUser} from '@tabler/icons-react';
import {useAuth} from "../auth/useAuth";
import {LinkGen} from "../LinkGen";

export const Menu = () => {
  const { logout } = useAuth();

  return (
    <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          To-Do MVP
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              <IconHome />
              Dashboard
            </Nav.Link>
            <Nav.Link as={Link} to="/websites">
              <IconListCheck />
              Lists
            </Nav.Link>
            <Nav.Link as={Link} to={LinkGen.CommonComponents()}>
              <IconListCheck />
              MVP Components
            </Nav.Link>
            <NavDropdown
              title={
                <>
                  <IconUser />
                  Account
                </>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/my-profile">
                My profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as="button" onClick={() => logout.mutateAsync()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
