import {FormikConfig, FormikErrors, FormikValues, useFormik} from 'formik';
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {AlertType, useAlertEmit} from "../alert/Alert";


type Values = FormikValues;
type Config<T extends Values> = FormikConfig<T>;

const axiosNetworkErrorMessages: Record<string, string> = {
    [AxiosError.ERR_NETWORK]: "There is an issue with your network connectivity",
    [AxiosError.ETIMEDOUT]: "Your action timed out. This might be due to a slow connection or an issue on our end.",
}

export function useForm<T extends Values>(props: Config<T>) {
    const emit = useAlertEmit();

    const popError = (content: string) => emit({
        type: AlertType.ERROR,
        content,
    });

    return useFormik<T>({
        ...props,
        onSubmit: async (values, helpers) => {
            try {
                await props.onSubmit(values, helpers);
            } catch (e) {
                if (isAxiosError(e)) {
                    if (e.response === undefined) { // Network error
                        if (e.code === AxiosError.ERR_CANCELED) { // Cancelled by consumer
                            return;
                        }

                        // Handle recognised error codes
                        if (e.code && axiosNetworkErrorMessages.hasOwnProperty(e.code)) {
                            popError(axiosNetworkErrorMessages[e.code]);
                            return;
                        }

                        popError("There was an unknown network issue")
                        return;
                    } else {
                        if (e.response.status === 422) {
                            helpers.setErrors(parseValidationErrors(e.response));
                            return;
                        } else {
                            popError(e.response.data?.message || 'Something went wrong.');
                            return;
                        }
                    }
                }

                // No error message matched - rethrow error
                throw e;
            }
        },
    });
}

const parseValidationErrors = <T extends any>(res: AxiosResponse<Record<string, string[]>>): FormikErrors<T> => {
    return Object.entries(res.data).reduce((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue[0]]: currentValue[1].join(' '),
    }), {});
}

