import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Api} from "../api.config";
import {SessionConsumeRequest, SessionRequestRequest} from "shared";
import {AlertType, useAlertEmit} from "../common/alert/Alert";
import {QueryKeys} from "../LinkGen";

export const useAuth = () => {
    const emit = useAlertEmit();
    const queryClient = useQueryClient();

    const requestSession = useMutation<void, any, SessionRequestRequest>({
        mutationFn: async x => {
            await Api.RequestSession(x);
            emit({
                type: AlertType.SUCCESS,
                content: 'You have been sent a login email. Check your email to proceed.'
            })
        }
    });

    const consumeSession = useMutation<void, any, SessionConsumeRequest>({
        mutationFn: async x => {
            await Api.ConsumeSessionRequest(x);
            await queryClient.refetchQueries({
                queryKey: QueryKeys.Profile(),
            })
        },
        onSuccess: () => emit({
            type: AlertType.SUCCESS,
            content: 'You have successfully logged in.'
        }),
    });

    const logout = useMutation({
        mutationFn: async () => {
            await Api.Logout();
            await queryClient.refetchQueries({
                queryKey: QueryKeys.Profile(),
            })
        },
        onSuccess: () => emit({
            type: AlertType.SUCCESS,
            content: 'You have successfully logged out.'
        }),
    });

    return {
        requestSession,
        consumeSession,
        logout,
    }
}