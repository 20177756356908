import {FC, PropsWithChildren} from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from 'react-helmet-async';
import {IconHomePlus} from '@tabler/icons-react';
import {Menu} from './Menu';
import {ExtraButton} from '../common/pure/ExtraButton';

type PageProps = PropsWithChildren<{
    title: string;
    pretitle?: string;
    toggleCreateOrganisation?: boolean;
}>;

export const Page: FC<PageProps> = (props) => {
    const {title, pretitle, toggleCreateOrganisation = true, children} = props;

    return (
        <>
            <Helmet defaultTitle="To-do MVP" titleTemplate="%s - To-do MVP">
                <title>{title}</title>
            </Helmet>
            <Menu/>
            <Container className="page-header" as="section">
                <div className="row g-2 align-items-center">
                    <div className="col">
                        {pretitle ? <div className="page-pretitle">{pretitle}</div> : null}
                        <h2 className="page-title">{title}</h2>
                    </div>
                    {toggleCreateOrganisation && (
                        <div className="col justify-content-end d-flex">
                            <ExtraButton onClick={() => undefined} icon={IconHomePlus}>
                                Create organisation
                            </ExtraButton>
                        </div>
                    )}
                </div>
            </Container>
            <Container className="page" as="section">
                {children}
            </Container>
            <Container as="footer" className="footer">
                Copyright &copy; 2024 <a href="https://fpsoftware.io/">Future-Proof Software Ltd.</a> All rights
                reserved.
            </Container>
        </>
    );
};
