import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAuth} from "./useAuth";
import {Empty} from "../layout/EmptyLayout";
import {IconLoader2, IconMail} from "@tabler/icons-react";
import {ExtraButton} from "../common/pure/ExtraButton";
import {LinkGen} from "../LinkGen";
import {ExtraCard} from "../common/pure/ExtraCard";

export const PageAuthConsume = () => {
    const {id, secret} = useParams();
    const {
        consumeSession: {mutate, error}
    } = useAuth();

    useEffect(() => {
        mutate({
            id: Number(id),
            secret: secret!
        })
    }, [id, secret, mutate]);

    return <Empty.Container>
        <Empty>
            <ExtraCard status={error ? 'danger' : undefined}>
                <ExtraCard.Body>
                    {error ? <>
                        <ExtraCard.Title>There was a problem logging you in.</ExtraCard.Title>
                        <p>Please try again using the link below</p>
                        <ExtraButton.Link icon={IconMail} to={LinkGen.Auth()}>Email me a new link</ExtraButton.Link>
                    </> : <>
                        <ExtraCard.Title><IconLoader2 className='icon-spin'/> You are being logged
                            in..</ExtraCard.Title>
                        <span>Please wait while we authenticate you. If you dont automatically get redirected, please click the button below.</span>
                    </>}
                </ExtraCard.Body>
            </ExtraCard>
        </Empty>
    </Empty.Container>;
}