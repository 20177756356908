import {Empty} from "./EmptyLayout";
import {IconLoader2} from "@tabler/icons-react";
import {useInterval} from "usehooks-ts";
import {useState} from "react";
import {Helmet} from "react-helmet-async";

export const PageLoading = () => {
    const [dots, setDots] = useState<number>(0);
    useInterval(() => {
        setDots(x => {
            if (x > 0 && x % 3 === 0) {
                return 0;
            }

            return x + 1;
        });
    }, 600);

    const dotsString = Array(dots).fill('.').join('');

    const title = 'Loading' + dotsString;
    const spaces = 'Loading' + dotsString + Array(3 - dots).fill('&nbsp;').join('');

    return <Empty.Container>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Empty className='text-center'>
            <IconLoader2 className='icon-spin mb-4' size={72} stroke={1.25}/>
            <Empty.Title dangerouslySetInnerHTML={{__html: spaces}}/>
        </Empty>
    </Empty.Container>
}