import React, {Suspense} from 'react';
import {HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {LinkGen} from "./LinkGen";
import {PageDashboard} from "./dashboard/PageDashboard";
import {AlertRootScope} from "./common/alert/AlertRootScope";
import {PageAuth} from "./auth/PageAuth";
import {PageAuthConsume} from "./auth/PageAuthConsume";
import {AuthRoutes} from "./auth/AuthRoutes";
import {PageNotFound} from "./layout/PageNotFound";
import {ModalProvider} from "./common/modal/ModalProvider";
import {PageCommonComponents} from "./components/PageCommonComponents";
import {PageLoading} from "./layout/PageLoading";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        }
    }
});

function App() {
    return (
        <HelmetProvider>
            <AlertRootScope>
                <Suspense fallback={<PageLoading/>}>
                    <QueryClientProvider client={queryClient}>
                        <ModalProvider>

                            <BrowserRouter>
                                {/*<ModalProvider>*/}
                                <Routes>
                                    <Route element={<AuthRoutes auth={false}/>}>
                                        <Route path={LinkGen.Auth()} element={<PageAuth/>}/>
                                        <Route path={LinkGen.AuthConsume(':id', ':secret')}
                                               element={<PageAuthConsume/>}/>
                                    </Route>
                                    <Route element={<AuthRoutes auth={true}/>}>
                                        {/*<Route element={<Journey/>}>*/}
                                            <Route path={LinkGen.Dashboard()} element={<PageDashboard/>}/>
                                            <Route path={LinkGen.CommonComponents()} element={<PageCommonComponents/>}/>
                                        {/*</Route>*/}
                                    </Route>
                                    <Route path='*' element={<PageNotFound/>}/>
                                </Routes>
                                {/*</ModalProvider>*/}
                            </BrowserRouter>
                        </ModalProvider>
                    </QueryClientProvider>
                </Suspense>
            </AlertRootScope>
        </HelmetProvider>
    );
}

export default App;
