import {useState} from "react";
import {AlertProps, Toast, ToastContainer} from "react-bootstrap";
import {Alert, AlertType, useAlertSubscribe} from "./Alert";
import {useEventCallback} from "usehooks-ts";

const variantType: Record<AlertType, AlertProps['variant']> = {
    [AlertType.ERROR]: 'danger',
    [AlertType.INFO]: 'info',
    [AlertType.SUCCESS]: 'success',
    [AlertType.WARNING]: 'warning',
}

const title: Record<AlertType, string> = {
    [AlertType.ERROR]: 'Error',
    [AlertType.INFO]: 'Information',
    [AlertType.SUCCESS]: 'Success',
    [AlertType.WARNING]: 'Warning',
}

export const AlertToasts = () => {
    const [alerts, setAlerts] = useState<Alert[]>([]);

    useAlertSubscribe(alert => {
        setAlerts(draft => [...draft, alert]);
    })

    const removeAlert = useEventCallback(alert => {
        setAlerts(draft => draft.filter(x => x !== alert));
    })

    if (alerts.length === 0) {
        return null;
    }

    return <ToastContainer position="top-end" className="p-3" style={{zIndex: 99999}}>
        {alerts.map((alert, i) => <Toast onClose={() => removeAlert(alert)} key={i}>
            <div className={`toast-status-top bg-${variantType[alert.type]}`}/>
            <Toast.Header>
                <strong className="me-auto">{title[alert.type]}</strong>
            </Toast.Header>
            <Toast.Body>{alert.content}</Toast.Body>
        </Toast>)}
    </ToastContainer>;
}