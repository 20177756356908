import {AuthCard} from "./AuthCard";
import logo from "./logo.svg";
import {Empty} from "../layout/EmptyLayout";

export const PageAuth = () => {
    return <Empty.Container>
        <Empty>
            <img src={logo} alt="Future-proof software"
                 style={{maxWidth: '50%', margin: '1rem auto', display: 'block'}}/>
            <AuthCard/>
        </Empty>
    </Empty.Container>
}