import {convert, Instant} from "@js-joda/core";
import {useCallback} from "react";


export const TimeFormatter = {
    DateTime: Intl.DateTimeFormat('en-GB', {
        day: "2-digit",
        month: "short",
        year: "2-digit",

        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
    Time: Intl.DateTimeFormat('en-GB', {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }),
    Date: Intl.DateTimeFormat('en-GB', {
        day: "numeric",
        month: "short",
        year: "2-digit",
    }),
}


export const useTime = () => {

    const format = useCallback((formatter: Intl.DateTimeFormat, time: string) => {
        return formatter.format(convert(Instant.parse(time)).toDate())
    }, []);

    return {
        format
    }
}