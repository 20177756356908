import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";
import {QueryKeys} from "../LinkGen";
import {Api} from "../api.config";
import {TaskCreateRequest} from "shared";
import {AlertType, useAlertEmit} from "../common/alert/Alert";

export const useTasks = () => {
    const {data} = useSuspenseQuery({
        queryKey: QueryKeys.Task.List(),
        queryFn: () => Api.TaskList().then(x => x.data),
    });

    return {tasks: data};
}

export const useTaskActions = () => {
    const queryClient = useQueryClient();
    const emit = useAlertEmit();
    const create = useMutation<any, any, TaskCreateRequest>({
        mutationFn: x => Api.TaskCreate(x),
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: QueryKeys.Task.List(),
            });
            emit({
                type: AlertType.SUCCESS,
                content: 'Task created successfully.'
            });
        }
    });

    const update = useMutation<any, any, TaskCreateRequest & {id: number}>({
        mutationFn: x => Api.TaskUpdate(x.id, x),
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: QueryKeys.Task.List(),
            });
            emit({
                type: AlertType.SUCCESS,
                content: 'Task updated successfully.'
            });
        }
    });

    const del = useMutation<any, any, number>({
        mutationFn: x => Api.TaskDelete(x),
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: QueryKeys.Task.List(),
            })
            emit({
                type: AlertType.SUCCESS,
                content: 'Task deleted successfully.'
            })
        }
    })

    return {
        create,
        update,
        del,
    };
}