export const LinkGen = {
    Dashboard: () => `/`,
    Auth: () => `/auth`,
    AuthConsume: (id: string | number, secret: string) => `/auth/${id}/${secret}`,
    Register: () => `/register`,
    MyProfile: () => `/my-profile`,
    Websites: {
        List: () => `/websites`,
        View: (id: string) => `/websites/${id}`,
    },
    Organisations: {
        View: (id: string) => `/organisations/${id}`,
    },
    CommonComponents: () => `/library/common`,
};

export const QueryKeys = {
    Profile: () => ['PROFILE'],
    Task: {
        List: () => ['TASK'],
    },
}

export const MutationKeys = {
    RequestSession: () => ['RequestSession'],
    ConsumeSession: () => ['ConsumeSession'],
}