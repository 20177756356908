import axios from "axios";
export class HttpInterface {
    constructor(config) {
        this.http = axios.create(config);
    }
    RequestSession(req) {
        return this.http.post('/api/auth/request', req);
    }
    ConsumeSessionRequest(req) {
        return this.http.post('/api/auth/consume', req);
    }
    Profile() {
        return this.http.get('/api/auth/profile');
    }
    Logout() {
        return this.http.post('/api/auth/logout');
    }
    TaskCreate(dto) {
        return this.http.post('/api/task', dto);
    }
    TaskUpdate(id, dto) {
        return this.http.patch(`/api/task/${id}`, dto);
    }
    TaskDelete(id) {
        return this.http.delete(`/api/task/${id}`);
    }
    TaskList() {
        return this.http.get('/api/task');
    }
}
