import {Modal, useModal} from "../common/modal/Modal"
import {useForm} from "../common/form/useForm";
import {ExtraForm} from "../common/pure/ExtraFormGroup";
import {IconEdit, IconPlus} from "@tabler/icons-react";
import {useTaskActions} from "./useTasks";
import {FC} from "react";
import {TaskDto} from "shared";

interface ModalTaskProps {
    task?: TaskDto;
}

export const ModalTask: FC<ModalTaskProps> = props => {
    const {task} = props;
    const {close} = useModal();
    const {create, update} = useTaskActions();
    const form = useForm({
        initialValues: {
            task: task?.task ?? '',
        },
        onSubmit: async values => {
            if(task === undefined) {
                await create.mutateAsync(values);
            } else {
                await update.mutateAsync({
                    id: task.id,
                    ...values,
                });
            }
            close();
        },
    });

    return <Modal.Form onSubmit={form.handleSubmit}
                       title={task ? 'Edit task' : 'Add task'}
                       confirmText={task ? 'Edit' : 'Add'}
                       confirmIcon={task ? IconEdit : IconPlus}
                       isSubmitting={form.isSubmitting}>
        <ExtraForm.Group label='Task' required>
            <ExtraForm.Control
                placeholder='Send 25 cold emails'
                {...form.getFieldProps('task')}
            />
        </ExtraForm.Group>
    </Modal.Form>
}