import {ComponentType, createContext, FC, PropsWithChildren, useCallback, useContext, useState} from 'react';

type OpenFn = <T extends object = {}>(modal: ComponentType<T>, props: T) => unknown;
type CloseFn = () => unknown;

interface IModalContext {
  open: OpenFn;
  close: CloseFn;
  state: ModalState<any>;
}

const DefaultFn = () => {
  throw new Error('Uninitialized modal provider');
};

export const ModalContext = createContext<IModalContext>({
  open: DefaultFn,
  close: DefaultFn,
  state: undefined,
});

interface ModalData<T extends object> {
  component: ComponentType<T>;
  props: PropsWithChildren<T>;
}

type ModalState<T extends object> = ModalData<T> | undefined;

const ModalContextProvider: FC<PropsWithChildren<{}>> = (props) => {
  const [state, setState] = useState<ModalState<any>>(undefined);

  const open = useCallback<OpenFn>(
    (component, props) => {
      setState({ component, props });
    },
    [setState],
  );

  const close = useCallback(() => {
    setState(undefined);
  }, [setState]);

  return <ModalContext.Provider value={{ state, open, close }}>{props.children}</ModalContext.Provider>;
};

const ModalPlaceholder: FC = () => {
  const { state } = useContext(ModalContext);

  const Modal = state?.component;
  return Modal ? <Modal {...state?.props} /> : null;
};

export const useModalContext = () => {
  const { close, open } = useContext(ModalContext);

  return [open, close] as const;
};

export const ModalProvider: FC<PropsWithChildren> = (props) => {
  return (
    <ModalContextProvider>
      {props.children}
      <ModalPlaceholder />
    </ModalContextProvider>
  );
};
