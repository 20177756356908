import {Page} from "../layout/Page";
import {Col, Container, Row, Table} from "react-bootstrap";
import {ExtraCard} from "../common/pure/ExtraCard";
import {ExtraButton} from "../common/pure/ExtraButton";
import {IconSend} from "@tabler/icons-react";
import {ButtonVariant} from "react-bootstrap/types";


const buttonLabels: Record<ButtonVariant, string> = {
    primary: 'Primary',
    secondary: 'Secondary',
    success: 'Success',
    warning: 'Warning',
    danger: 'Danger',
    info: 'Info',
    cancel: 'Cancel',
}

export const PageCommonComponents = () => {
    return <Page title='Common components'>
        <Container>
            <Row>
                <Col xs={12} md={3}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard.Body>
                            This is a plain card, without a header or a title. It is the basic building block of
                            layouts.
                        </ExtraCard.Body>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={3}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard.Body>
                            <ExtraCard.Title>Card with title</ExtraCard.Title>
                            This card has a title, helping users understand what they are looking at
                        </ExtraCard.Body>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={3}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard.Header>Card with header</ExtraCard.Header>
                        <ExtraCard.Body>
                            <span>This is a card with header. Hears are useful when displaying card with tables or forms</span>
                        </ExtraCard.Body>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={3}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard>
                            <ExtraCard.Body>
                                <ExtraCard.Title>Card footer</ExtraCard.Title>
                                A footer can be used to display extra information or actions.
                            </ExtraCard.Body>
                            <ExtraCard.FooterActions>
                                <span>just now</span>
                            </ExtraCard.FooterActions>
                        </ExtraCard>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={3}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard>
                            <ExtraCard.Body>
                                <ExtraCard.Title>Card actions</ExtraCard.Title>
                                <p>Do you want to send this important email?</p>
                                <small><i>Note: This action is irreversible.</i></small>
                            </ExtraCard.Body>
                            <ExtraCard.FooterActions>
                                <ExtraButton variant='cancel'>Cancel</ExtraButton>
                                <ExtraButton icon={IconSend}>Send</ExtraButton>
                            </ExtraCard.FooterActions>
                        </ExtraCard>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={9}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard>
                            <ExtraCard.Body>
                                <ExtraCard.Title>Standard Buttons</ExtraCard.Title>
                                <div>
                                    <span style={{
                                        display: 'inline-block',
                                        width: 100
                                    }}>Normal:</span>{Object.keys(buttonLabels).map(variant => <ExtraButton
                                    className='mb-1 me-1'
                                    variant={variant}>{buttonLabels[variant]}</ExtraButton>)}
                                </div>
                                <div>
                                    <span style={{
                                        display: 'inline-block',
                                        width: 100
                                    }}>Active:</span>{Object.keys(buttonLabels).map(variant => <ExtraButton
                                    className='mb-1 me-1 active'
                                    variant={variant}>{buttonLabels[variant]}</ExtraButton>)}
                                </div>
                                <div>
                                    <span style={{
                                        display: 'inline-block',
                                        width: 100
                                    }}>Disabled:</span>{Object.keys(buttonLabels).map(variant => <ExtraButton
                                    className='mb-1 me-1' disabled
                                    variant={variant}>{buttonLabels[variant]}</ExtraButton>)}
                                </div>

                            </ExtraCard.Body>
                        </ExtraCard>
                    </ExtraCard>
                </Col>
                <Col xs={12} md={9}>
                    <ExtraCard className='mb-3'>
                        <ExtraCard>
                            <ExtraCard.Header>Table (Population 2023)</ExtraCard.Header>
                            <Table responsive hover className='mb-0'>
                                <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Population</th>
                                    <th>World share</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>India</td>
                                    <td>1,428,627,663</td>
                                    <td>17.76%</td>
                                </tr>
                                <tr>
                                    <td>China</td>
                                    <td>1,425,671,352</td>
                                    <td>17.72%</td>
                                </tr>
                                <tr>
                                    <td>United States</td>
                                    <td>339,996,563</td>
                                    <td>4.23%</td>
                                </tr>
                                <tr>
                                    <td>Indonesia</td>
                                    <td>277,534,122</td>
                                    <td>3.45%</td>
                                </tr>
                                <tr>
                                    <td>Pakistan</td>
                                    <td>240,485,658</td>
                                    <td>2.99%</td>
                                </tr>
                                </tbody>
                            </Table>
                        </ExtraCard>
                    </ExtraCard>
                </Col>
            </Row>
        </Container>
    </Page>
}