import {Navigate, Outlet} from 'react-router-dom';
import {FC} from "react";
import {LinkGen, QueryKeys} from "../LinkGen";
import {useSuspenseQuery} from "@tanstack/react-query";
import {Api} from "../api.config";
import {isAxiosError} from "axios";

interface ProtectedRoutesProps {
    auth: boolean;
}

export const AuthRoutes: FC<ProtectedRoutesProps> = props => {
    const {data} = useSuspenseQuery({
        queryKey: QueryKeys.Profile(),
        queryFn: () => Api.Profile()
            .then(x => x.data)
            .catch(e => {
                if (isAxiosError(e) && e.response?.status === 401) {
                    // Handle log our/log in requirement
                    return null;
                } else {
                    throw e;
                }
            }),
    })

    if (props.auth && data === null) {
        return <Navigate to={LinkGen.Auth()}/>
    }

    if (!props.auth && data !== null) {
        return <Navigate to={LinkGen.Dashboard()}/>
    }

    return <Outlet/>;
};
