import {Empty} from "./EmptyLayout";
import {ExtraButton} from "../common/pure/ExtraButton";
import {IconArrowLeft} from "@tabler/icons-react";
import {LinkGen} from "../LinkGen";

export const PageNotFound = () => {
    return <Empty.Container>
        <Empty className='text-center'>
            <Empty.Header>404</Empty.Header>
            <Empty.Title>Oops… You just found an error page</Empty.Title>
            <Empty.Subtitle>We are sorry but the page you are looking for was not found.</Empty.Subtitle>
            <ExtraButton.Link to={LinkGen.Dashboard()} icon={IconArrowLeft} >Take me home</ExtraButton.Link>
        </Empty>
    </Empty.Container>
}