import {Card, Form} from "react-bootstrap";
import {useForm} from "../common/form/useForm";
import {ExtraForm} from "../common/pure/ExtraFormGroup";
import {ExtraButton} from "../common/pure/ExtraButton";
import {IconMail} from "@tabler/icons-react";
import {ExtraCard} from "../common/pure/ExtraCard";
import React, {ComponentProps, FC} from "react";
import {useAuth} from "./useAuth";
import {toFormikValidationSchema} from 'zod-formik-adapter';
import {z} from 'zod';

const Schema = z.object({
    email: z.string({
        required_error: "Email field is required."
    }).email("Email is not valid."),
});

export const AuthCard: FC<ComponentProps<typeof Card>> = props => {
    const {requestSession} = useAuth();

    const form = useForm({
        validationSchema: toFormikValidationSchema(Schema),
        initialValues: {
            email: '',
        },
        onSubmit: values => requestSession.mutateAsync(values),
    });

    return <Card {...props} data-testid='card-auth'>
        <Form onSubmit={form.handleSubmit}>
            <Card.Body>
                <Card.Title>Login</Card.Title>
                <p>Enter your email below and we will send you a login link. Just press the login button - there is no
                    need for a password.</p>
                <ExtraForm.Group label='Email address'>
                    <ExtraForm.Control
                        placeholder='john.doe@example.com'
                        type='email'
                        data-testid='card-auth-email'
                        touched={form.touched.email}
                        error={form.errors.email}
                        {...form.getFieldProps('email')}
                    />
                </ExtraForm.Group>

            </Card.Body>
            <ExtraCard.FooterActions>
                <ExtraButton icon={IconMail} loading={form.isSubmitting} type='submit'>
                    Send email
                </ExtraButton>
            </ExtraCard.FooterActions>
        </Form>
    </Card>
}