import {Card} from "react-bootstrap";
import {Page} from "../layout/Page";
import {TaskTable} from "../task/TaskTable";
import {IconPlus} from "@tabler/icons-react";
import {ExtraButton} from "../common/pure/ExtraButton";
import {useModal} from "../common/modal/Modal";
import {ModalTask} from "../task/ModalTask";

export const PageDashboard = () => {
    const {open} = useModal();

    const handleNew = () => open(ModalTask, {})

    return <Page title='Dashboard'>
        <Card className='mb-3'>
            <Card.Header>
                <Card.Title>My to-do list</Card.Title>
                <div>
                    <ExtraButton icon={IconPlus} onClick={handleNew} size='sm'>
                        Add new
                    </ExtraButton>
                </div>
            </Card.Header>
            <TaskTable/>
        </Card>
    </Page>
}