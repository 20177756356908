import {Container} from "react-bootstrap";
import React, {FC, PropsWithChildren} from "react";
import {DivProps} from "../types";
import cn from "classnames";

const EmptyContainer: FC<PropsWithChildren> = props => {
    return <Container className='empty-container'>
        {props.children}
    </Container>
}

const Empty: FC<DivProps> = props => {
    const {children, className, ...rest} = props;
    return <div className={cn('empty', className)} {...rest}>
        {props.children}
    </div>
}

const EmptyHeader: FC<PropsWithChildren> = props => {
    return <div className='empty-header'>
        {props.children}
    </div>
}

const EmptyTitle: FC<DivProps> = props => {
    const {className, ...rest} = props;

    return <p className={cn('empty-title', className)} {...rest} />
}

const EmptySubtitle: FC<PropsWithChildren> = props => {
    return <p className='empty-subtitle'>
        {props.children}
    </p>
}

const EmptyNamespace = Object.assign(Empty, {
    Header: EmptyHeader,
    Title: EmptyTitle,
    Subtitle: EmptySubtitle,
    Container: EmptyContainer,
})

export {EmptyNamespace as Empty}