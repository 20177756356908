import {Card as BsCard} from "react-bootstrap";
import cn from "classnames";
import {ComponentProps, FC} from "react";
import {Variant} from "react-bootstrap/types";

const FooterActions: FC<ComponentProps<typeof BsCard.Footer>> = props => {
    const {className, children, ...rest} = props;

    return <BsCard.Footer className={cn('card-footer-actions', className)} {...rest}>
        {children}
    </BsCard.Footer>
}

interface ExtraCardProps extends ComponentProps<typeof BsCard> {
    status?: Variant;
}

const Card: FC<ExtraCardProps> = props => {
    const {status, className, children, ...rest} = props;
    return <BsCard className={cn({
        'card-borderless': status !== undefined
    }, className)} {...rest}>
        {status && <div className={cn('card-status-top', `bg-${status}`)} />}
        {children}
    </BsCard>
}


const ExtraCardNamespace = Object.assign(Card, {
    FooterActions,
    Header: BsCard.Header,
    Body: BsCard.Body,
    Title: BsCard.Title,
})


export {ExtraCardNamespace as ExtraCard};