import {ComponentProps, FC} from 'react';
import {Button, ButtonProps} from 'react-bootstrap';
import {IconCheck, IconLoader2} from '@tabler/icons-react';
import cn from 'classnames';
import {Link} from "react-router-dom";
import {ButtonVariant} from "react-bootstrap/types";

interface ExtraButtonProps extends ButtonProps {
    icon?: typeof IconCheck; // any icon really
    variant?: ButtonProps['variant'] | 'cancel';
    loading?: boolean;
}

const ExtraButton: FC<ExtraButtonProps> = (props) => {
    let {icon: Icon} = props;
    const {loading = false, disabled, className, ...rest} = props;

    if (loading) {
        Icon = IconLoader2;
    }

    const classes = cn(className, {
        'btn-loading': loading,
    });

    return (
        <Button disabled={loading || disabled} className={classes} {...rest}>
            {Icon && <Icon role={loading ? 'status' : undefined}/>}
            {props.children}
        </Button>
    );
};


interface ExtraButtonLinkProps extends ComponentProps<typeof Link> {
    variant?: ButtonVariant;
    icon?: typeof IconCheck;
}

const ExtraButtonLink: FC<ExtraButtonLinkProps> = props => {
    const {className, variant = 'primary', children, icon: Icon,...rest} = props;
    return <Link className={cn(`btn btn-${variant}`, className)} {...rest}>
        {Icon && <Icon/>}
        {children}
    </Link>
}

const ExtraButtonNamespace = Object.assign(ExtraButton, {
    Link: ExtraButtonLink,
});

export {ExtraButtonNamespace as ExtraButton}