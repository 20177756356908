import ReactDOM from 'react-dom';
import {Button, ButtonProps, Form, Modal as BsModal} from 'react-bootstrap';
import {FC, FormEventHandler, PropsWithChildren, useState} from 'react';
import {useModalContext} from './ModalProvider';
import {ExtraButton} from '../pure/ExtraButton';
import {IconCheck} from '@tabler/icons-react';
import {useEventCallback} from "usehooks-ts";

interface BaseModalProps {
  onCancel?: () => unknown;
  title: string;
  size?: 'sm' | 'lg' | 'xl';
}

interface PortalModalProps extends BaseModalProps, PropsWithChildren {
  backdropDismiss?: boolean;
  escapeDismiss?: boolean;
}

const Modal: FC<PortalModalProps> = (props) => {
  const { backdropDismiss = false, escapeDismiss = true, size, title, children, onCancel } = props;

  const { close } = useModal();

  const handleCancel = () => {
    if (onCancel === undefined) {
      return close();
    }

    onCancel();
  };

  return ReactDOM.createPortal(
    <BsModal
      show
      onHide={handleCancel}
      backdrop={backdropDismiss ? true : 'static'}
      keyboard={escapeDismiss}
      size={size}
      data-testid="modal"
    >
      <BsModal.Header closeButton>
        <BsModal.Title as="span" data-testid="modal-title">
          {title}
        </BsModal.Title>
      </BsModal.Header>
      {children}
    </BsModal>,
    document.getElementById('root-modal')!,
  );
};

interface ModalFormProps extends BaseModalProps, PropsWithChildren {
  isSubmitting?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  variant?: ButtonProps['variant'];
  confirmDisabled?: boolean;
  confirmIcon?: typeof IconCheck;
  confirmText?: string;
}

const ModalForm: FC<ModalFormProps> = (props) => {
  const {
    isSubmitting = false,
    children,
    onSubmit,
    variant = 'primary',
    confirmText = 'Submit',
    confirmIcon,
    confirmDisabled = false,
    onCancel,
    ...baseModalProps
  } = props;
  const { close } = useModal();

  const handleCancel = () => {
    if (onCancel === undefined) {
      return close();
    }

    onCancel();
  };

  return (
    <Modal {...baseModalProps} backdropDismiss={false} escapeDismiss={false}>
      <Form onSubmit={onSubmit} data-testid="modal-form">
        <BsModal.Body>{children}</BsModal.Body>
        <BsModal.Footer className="modal-footer-confirm">
          <Button variant="cancel" type="button" onClick={handleCancel} data-testid="modal-cancel">
            Cancel
          </Button>
          <ExtraButton
            variant={variant}
            icon={confirmIcon}
            type="submit"
            disabled={confirmDisabled || isSubmitting}
            loading={isSubmitting}
            data-testid="modal-submit"
          >
            {confirmText}
          </ExtraButton>
        </BsModal.Footer>
      </Form>
    </Modal>
  );
};

interface ModalConfirmProps extends BaseModalProps, PropsWithChildren {
  onConfirm?: () => Promise<any>;
  variant?: ButtonProps['variant'];
  confirmText?: string;
}

const ModalConfirm: FC<ModalConfirmProps> = (props) => {
  const { onConfirm, variant = 'primary', children, ...rest } = props;
  const { close } = useModal();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useEventCallback((e: any) => {
    e.preventDefault();
    if (onConfirm === undefined) {
      return;
    }

    setSubmitting(true);
    return onConfirm()
      .then((x) => {
        close();

        return x;
      })
      .finally(() => setSubmitting(false));
  });

  return (
    <ModalForm variant={variant} isSubmitting={submitting} onSubmit={handleSubmit} {...rest}>
      {children}
    </ModalForm>
  );
};

const ModalNamespace = Object.assign(Modal, {
  Form: ModalForm,
  Confirm: ModalConfirm,
});

export const useModal = () => {
  const [open, close] = useModalContext();

  const confirm = (props: ModalConfirmProps) => open(ModalConfirm, props);

  return { confirm, open, close };
};

export { ModalNamespace as Modal };
